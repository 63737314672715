@import url('./__list/projects__list.css');
@import url('./__list/__list-item/projects__list-item.css');
@import url('./__list/__list-item/__list-item-content/projects__list-item-content.css');
@import url('./__list/__list-item/__list-item-img/projects__list-item-img.css');
@import url('./__list/__list-item/__list-item-button/projects__list-item-button.css');
@import url('./__list/__list-item/__list-item-desc/projects__list-item-desc.css');
@import url('./__list/__list-item/__list-item-tag/projects__list-item-tag.css');

.projects {
    display: flex;
    flex-direction: column;
    gap: var(--section-gap);
}