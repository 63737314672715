@import url('./__text/acquaintance__text.css');
@import url('./__text-main/acquaintance__text-main.css');
@import url('./__text-main/_color-blue/acquaintance__text-main_blue.css');
@import url('./__text-main/_color-yellow/acquaintance__text-main_color-yellow.css');
@import url('./__text-main/_color-violet/acquaintance__text-main_color-violet.css');
@import url('./__text-secondary/acquaintance__text-secondary.css');
@import url('./__image/acquaintance__image.css');

.acquaintance {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@media (width < 778px) {
    .acquaintance {
        flex-direction: column-reverse;
    }
}