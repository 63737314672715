.popup_is-opened {
    display: flex;
    visibility: visible;
    opacity: 1;
    pointer-events: all;
    transition: visibility 0s, opacity 0.3s;
}

.popup_is-opened > .popup__content {
    animation: contentOpenedUp 0.3s ease-in-out forwards;
}

@keyframes contentOpenedUp {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-20px);
    }
}