@import url('./__copyright/footer__copyright.css');

.footer {
    flex-shrink: 0;
    padding: 30px 0 60px;
}

@media screen and (max-width: 568px) {
  .footer {
    padding: 30px 0 36px;
  }
}
