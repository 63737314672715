.page__content {
    background-color: var(--bg-color);
    min-height: 100vh;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    flex-direction: column;
    gap: 100px;
}

@media (width < 778px) {
    .page__content {
        gap: 50px;
    }
}
