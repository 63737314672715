.footer__copyright {
  line-height: 22px;
  color: var(--text-color);
  margin: 0;
}

@media screen and (max-width: 568px) {
  .footer__copyright {
    font-size: 14px;
    line-height: 17px;
  }
}
