.projects__list-item-button {
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: var(--main-color);
    border-radius: 10px;
    color: var(--accent-color);
    padding: 20px;
    border: none;
    cursor: pointer;
    text-align: left;
    position: relative;
    transition: transform .3s;
}

.projects__list-item-button::after {
    content: '';
    background-image: url(../../../../../images/transfer-icon.svg);
    background-position: 50%;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    inset: 0;
    border-radius: 10px;
    border: 3px solid #fff;
    background-color: #000000c7;
    opacity: 0;
    transition: opacity .3s;
}

.projects__list-item-button:hover::after {
    opacity: 1;
}

.projects__list-item-button:hover {
    transform: scale(0.97);
}