.about__text-more {
    height: 50px;
    overflow: hidden;
    flex-direction: column;
    gap: 27px;
    position: relative;
}

.about__text-more::after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
    background: linear-gradient(0deg, var(--bg-color) 0%, rgba(30,30,30,0) 100%);
}