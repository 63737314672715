@import url('./_type_transfer/popup_type_transfer.css');
@import url('./__content/popup__content.css');
@import url('./__content/__content-image/popup__content-image.css');
@import url('./__content/__content-main/popup__content-main.css');
@import url('./__content/__content-text/popup__content-text.css');
@import url('./__transfer-list/popup__transfer-list.css');
@import url('./__transfer-link/_type_site/popup__transfer-link_type_site.css');
@import url('./__transfer-link/_type_github/popup__transfer-link_type_github.css');
@import url('./__close/popup__close.css');

.popup {
    font-family: var(--title-font);
    color: var(--accent-color);
    display: none;
    align-items: center;
    justify-content: center;
    background-color: #000000b3;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}

@media (width < 778px) {
    .popup {
        margin: 0 20px;
    }
}