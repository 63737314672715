.popup__close {
    width: 30px;
    height: 30px;
    background: transparent url('../../../images/close-icon.svg') center no-repeat;
    background-size: 30px 30px;
    border: none;
    position: absolute;
    top: -36px;
    right: -34px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);
    transition: visibility 0s, opacity 0.3s;
    padding: 0;
    margin: 0;
    cursor: pointer;
  }
  
  .popup__close:hover {
    opacity: 0.6;
  }

  @media (width < 778px) {
      .popup__close {
        right: 0;
      }
  }