.projects__list-item-desc {
    font-weight: var(--main-weight);
    height: 50px;
    overflow: hidden;
    position: relative;
}

.projects__list-item-desc::before {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    height: 100%;
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgba(30,30,30,0) 100%);
}