@import url("./__logo/header__logo.css");
@import url("./__list/header__list.css");

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 30px 0 0 0;
}

@media (width < 778px) {
    .header {
      flex-direction: column;
      gap: 10px;
    }
}
