.popup_is-animated {
    display: flex;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    user-select: none;
    transition: visibility 0s 0.6s, opacity 0.3s;
}

.popup_is-animated > .popup__content {
    animation: contentOpenedDown 0.3s ease-in-out forwards;
}

@keyframes contentOpenedDown {
    from {
        transform: translateY(-20px);
    }
    to {
        transform: translateY(0);
    }
}