.acquaintance__image {
    position: relative;
}

.acquaintance__image img {
    width: 270px;
    animation: bubbleMovementImg 3s ease-in-out alternate infinite;
}

.acquaintance__image::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 21px;
    height: 21px;
    background-image: url(../../../images/acquaintance-image-after.svg);
}

.acquaintance__image::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 21px;
    height: 21px;
    background-image: url(../../../images/acquaintance-image-before.svg);
}