.popup__content-main {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media (width < 778px) {
    .popup__content-main {
        gap: 20px;
    }
}