.projects__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
}

@media (width < 778px) {
    .projects__list {
        grid-template-columns: 1fr;
    }
}