:root {
  --bg-color: #111;
  --main-color: #fff;
  --text-color: #b9b9b9;
  --outline-color: #3a86d3;
  --accent-color: #000;
  --bg-dark: #393939;
  --title-font: "Oswald", sans-serif, Helvetica;
  --title-weight: 400;
  --main-font: "Roboto", sans-serif, Helvetica;
  --main-weight: 300;
  --bold-weight: 800;
  --font-size: 16px;
  --middle-font-size: 24px;
  --title-font-size: 36px;
  --section-gap: 27px;
}
