.popup__content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    max-width: 840px;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
    box-sizing: border-box;
    padding: 30px;
}

@media (width < 778px) {
    .popup__content {
        grid-template-columns: 1fr;
        padding: 10px;
        gap: 20px;
    }
}