@import url('./__text/about__text.css');
@import url('./__text-more/about__text-more.css');
@import url('./__text/_collapsed/about__text_collapsed.css');
@import url('./__text/-list/-item/about__text-list-item.css');

.about {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--section-gap);
}