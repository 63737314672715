a{
    color: var(--accent-color);
}

ul, li {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

h1,
h2 {
    font-family: var(--title-font);
    font-size: var(--title-font-size);
    font-weight: var(--title-weight);
    color: var(--main-color);
}

h3 {
    font-family: var(--title-font);
    font-size: var(--middle-font-size);
    font-weight: var(--bold-weight);
}

.button {
    cursor: pointer;
    color: var(--text-color);
    background-color: var(--bg-dark);
    border-radius: 5px;
    border: none;
    padding: 5px 10px;
}

.button:hover {
    outline: 4px solid var(--outline-color);
}

.link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 5px;
    text-decoration: none;
}

.link_size_s {
    padding: 5px 10px;
}

.link_size_l {
    padding: 15px 10px;
}

.link_bg_white {
    background-color: var(--main-color);
    color: var(--accent-color);
}

.link_bg_dark {
    background-color: var(--main-color);
    color: var(--accent-color);
}
    
.link:hover {
    outline: 4px solid var(--outline-color);
}